import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid padding">
        <div className="row text-center">
          <div className="col-md-12">
            <img src="./img/fulllogo.png" alt="" />
            <p>More Coming Soon</p>
          </div>
          <hr className="light-100" />
        </div>
        <div>
          <h5 className="text-center">&copy;silwal.com</h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
