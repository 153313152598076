import React, { Component } from "react";

class Cards extends Component {
  state = {};
  render() {
    return (
      <React.StrictMode>
        <div class="container-fluid padding">
          <div class="row welcome text-center">
            <div class="col-12">
              <h2 class="display-4">Projects that we are working on</h2>
            </div>
            <hr />
          </div>
        </div>
        <div class="container-fluid padding">
          <div class="row padding">
            <div class="col-md-4">
              <img src="./img/card1.png" alt="" class="card-img-top" />
              <div class="card-body">
                <h4 class="card-title">Cooking</h4>
                <p class="card-text">
                  Our Attemp to Cook food from around the world
                </p>
                <a href="#" class="btn btn-outline-secondary">
                  More Info
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <img src="./img/card2.png" alt="" class="card-img-top" />
              <div class="card-body">
                <h4 class="card-title">Photography</h4>
                <p class="card-text">More Photogrpahy</p>
                <a href="#" class="btn btn-outline-secondary">
                  More Info
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <img src="./img/card3.png" alt="" class="card-img-top" />
              <div class="card-body">
                <h4 class="card-title">Coding</h4>
                <p class="card-text">Coding</p>
                <a href="#" class="btn btn-outline-secondary">
                  More Info
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.StrictMode>
    );
  }
}

export default Cards;
