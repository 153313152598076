import React from "react";

import NavBar from "./components/navbar";
import Carousal from "./components/carousel";
import Cards from "./components/card";
import Footer from "./components/footer";
function App() {
  return (
    <React.StrictMode>
      <NavBar />
      <Carousal />
      <Cards />
      <Footer />
    </React.StrictMode>
  );
}

export default App;
