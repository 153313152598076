import React from "react";

const Carousal = () => {
  return (
    <div className="carousel slide" id="slides" data-ride="carousel">
      <ul className="carousel-indicators">
        <li data-target="#slides" data-slide-to="0" className="active"></li>
        <li data-target="#slides" data-slide-to="1"></li>
        <li data-target="#slides" data-slide-to="2"></li>
      </ul>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="./img/background3.jpg" alt="" />
          <div className="carousel-caption">
            <h1 className="display-2">Silwal.com</h1>
            <h3>Full Website Coming soon</h3>
            <button className="btn btn-outline-light btn-lg" type="menu">
              VIEW
            </button>
            <button className="btn btn-primary btn-lg">Get Started</button>
          </div>
        </div>
        <div className="carousel-item">
          <img src="./img/background2.jpg" alt="" />
        </div>
        <div className="carousel-item">
          <img src="./img/background.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Carousal;
